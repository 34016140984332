import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { employeeService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Employee = function () {
  const collectionName = 'Employees'
  const singleCollectionName = 'Employee'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    employeeService.getEmployees
  )
  const createMutation = reactQuery.createMutation(
    employeeService.createEmployee,
    singleCollectionName,
    queryKey
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'role',
                label: 'Role',
                type: 'text',
                required: true,
              },
              {
                name: 'yearOfBirth',
                label: 'Year Of Birth',
                type: 'number',
              },
              {
                name: 'experience',
                label: 'Experience',
                type: 'number',
              },
              {
                name: 'phoneNumber',
                label: 'Phone Number',
                type: 'text',
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Employee
