import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import {
  cashflowService,
  itemService,
  supplierService,
  typeService,
} from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Expense = function () {
  const collectionName = 'Cashflow'
  const singleCollectionName = collectionName
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    cashflowService.getCashflow
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams(
              objectsHelper.cleanEmpty({
                ...Object.fromEntries([...searchParams]),
                ...args,
                query: collectionName,
              })
            ),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'month',
                label: 'Month',
                type: 'number',
                value: queryParams.month,
                max: 12,
              },
              {
                name: 'year',
                label: 'Year',
                type: 'number',
                value: queryParams.year,
              },
              {
                name: 'type',
                label: 'Cashflow Type',
                type: 'select',
                options: ['', ...types.types].map((type) => ({
                  label: type,
                  value: type,
                })),
                value: queryParams.type,
              },
              {
                name: 'typeName',
                label: 'Type',
                type: 'autocomplete',
                queryKey: 'types',
                getApi: typeService.getTypes,
                keys: { _id: 'typeId', name: 'typeName' },
                value: {
                  typeId: queryParams.typeId,
                  typeName: queryParams.typeName,
                },
              },
              {
                name: 'itemName',
                label: 'Item',
                type: 'autocomplete',
                queryKey: 'items',
                getApi: itemService.getItems,
                keys: { _id: 'itemId', name: 'itemName' },
                value: {
                  itemId: queryParams.itemId,
                  itemName: queryParams.itemName,
                },
                options: { typeId: body.typeId },
              },
              {
                name: 'supplierName',
                label: 'Supplier',
                type: 'autocomplete',
                queryKey: 'suppliers',
                getApi: supplierService.getSuppliers,
                keys: { _id: 'supplierId', name: 'supplierName' },
                value: {
                  supplierId: queryParams.supplierId,
                  supplierName: queryParams.supplierName,
                },
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      navigate={['type', '_id']}
    />
  )
}
export default Expense
