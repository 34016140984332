import dateUtils from '../../../_helpers/dateUtils'

const DayDate = () => (rowData) =>
  rowData.day
    ? dateUtils.formatDateString(
        new Date(rowData.year, rowData.month - 1, rowData.day)
      )
    : ''

export default DayDate
