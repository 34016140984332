import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { noteService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Note = function () {
  const collectionName = 'Notes'
  const singleCollectionName = 'Note'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    noteService.getNote
  )
  const updateMutation = reactQuery.updateMutation(
    noteService.updateNote,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    noteService.deleteNote,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectsHelper.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                value: getQuery.data?.name,
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
                value: getQuery.data?.note,
              },
              {
                name: 'extras',
                label: 'Extras',
                type: 'boolean',
                value: getQuery.data?.extras,
              },
              ...(getQuery.data?.extras
                ? [
                    {
                      name: 'price',
                      label: 'Price',
                      type: 'number',
                      value: getQuery.data?.price,
                    },
                  ]
                : []),
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Note
