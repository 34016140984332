import axios from '../_helpers/axios'

const route = 'Items'.toLowerCase()
const createItem = async (createBody) => axios.post(`v1/${route}`, createBody)
const getItems = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getItem = async (id) => axios.get(`v1/${route}/${id}`)
const updateItem = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteItem = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createItem,
  getItems,
  getItem,
  updateItem,
  deleteItem,
}
