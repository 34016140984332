import axios from '../_helpers/axios'

const route = 'users'
const createUser = async (createBody) => axios.post(`v1/${route}`, createBody)
const getUsers = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getUser = async (userEmail) => axios.get(`v1/${route}/${userEmail}`)
const updateUser = async (userEmail, updateBody) =>
  axios.patch(`v1/${route}/${userEmail}`, updateBody)
const deleteUser = async (userEmail) => axios.delete(`v1/${route}/${userEmail}`)
const getUserById = async (userId) => axios.get(`v1/${route}/user/${userId}`)
export default {
  createUser,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  getUserById,
}
