import TextField from '@mui/material/TextField'
import React from 'react'

import translate from '../../../locales/en'

function Number(props) {
  return (
    <TextField
      margin="normal"
      label={translate[props.label]}
      type="number"
      fullWidth
      InputLabelProps={{ shrink: true }}
      key={`${props.index}TextField`}
      autoFocus={props.index === 0}
      disabled={props.disabled}
      inputProps={{ min: props.min || 0, max: props.max, step: props.step }}
      value={props.value}
      onChange={(event) => props.onChange(event.target.value)}
      required={props.required}
      error={
        (props.required && !props.value) || (props.value && +props.value < 0)
      }
      {...((props.required &&
        !props.value && {
          helperText: `${translate[props.label]} ${translate['is required']}`,
        }) ||
        (props.value &&
          +props.value < 0 && {
            helperText: `${translate[props.label]} ${translate['is invalid']}`,
          }))}
    />
  )
}

export default Number
