import axios from '../_helpers/axios'

const route = 'Paychecks'.toLowerCase()
const createPaycheck = async (createBody) =>
  axios.post(`v1/${route}`, createBody)
const getPaychecks = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getPaycheck = async (id) => axios.get(`v1/${route}/${id}`)
const updatePaycheck = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deletePaycheck = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createPaycheck,
  getPaychecks,
  getPaycheck,
  updatePaycheck,
  deletePaycheck,
}
