import axios from '../_helpers/axios'

const route = 'Incomes'.toLowerCase()
const createIncome = async (createBody) => axios.post(`v1/${route}`, createBody)
const getIncomes = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getIncome = async (id) => axios.get(`v1/${route}/${id}`)
const updateIncome = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteIncome = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createIncome,
  getIncomes,
  getIncome,
  updateIncome,
  deleteIncome,
}
