import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import stringUtils from '../../_helpers/stringUtils'
import {
  eventService,
  expenseService,
  incomeService,
  itemService,
  noteService,
  userService,
} from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'
import Events from '../Collections/Events'
import Expenses from '../Collections/Expenses'
import Incomes from '../Collections/Incomes'
import Guard from '../Guards/Guard'
import RoleBasedGuard from '../Guards/RoleBasedGuard'

const Event = function () {
  const collectionName = 'Events'
  const singleCollectionName = 'Event'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    eventService.getEvent
  )
  const updateMutation = reactQuery.updateMutation(
    eventService.updateEvent,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    eventService.deleteEvent,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  const addStaffMutation = reactQuery.subCreateMutation(
    eventService.addStaff,
    params.id,
    'Staff',
    singleQueryKey,
    {}
  )
  const removeStaffMutation = reactQuery.subDeleteMutation(
    eventService.removeStaff,
    params.id,
    undefined,
    'Staff',
    singleQueryKey
  )
  const createExpenseMutation = reactQuery.createMutation(
    expenseService.createExpense,
    'Expense',
    'expenses',
    [['event', { id: params.id }]]
  )
  const createIncomeMutation = reactQuery.createMutation(
    incomeService.createIncome,
    'Income',
    'incomes',
    [['event', { id: params.id }]]
  )
  const returnEventMutation = reactQuery.createMutation(
    eventService.returnEvent,
    'Quotation',
    'quotations'
  )
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <>
      <Document
        actions={[
          {
            buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              const { start, end } = args
              delete args.start
              delete args.end
              if (start) {
                ;[args.startHour, args.startMinute] = start.split(':')
                args.startHour = +args.startHour
                args.startMinute = +args.startMinute
              }
              if (end) {
                ;[args.endHour, args.endMinute] = end.split(':')
                args.endHour = +args.endHour
                args.endMinute = +args.endMinute
              }
              const newBody = objectsHelper.compare(getQuery.data, args)
              if (Object.keys(newBody).length)
                return updateMutation.mutate(newBody)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Update} ${translate[singleCollectionName]}`,
              inputs: [
                {
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  value: getQuery.data.name,
                },
                ...(getQuery.data.eventType === 'Wedding'
                  ? [
                      {
                        name: 'groomName',
                        label: 'Groom Name',
                        type: 'text',
                        value: getQuery.data.groomName,
                      },
                      {
                        name: 'brideName',
                        label: 'Bride Name',
                        type: 'text',
                        value: getQuery.data.brideName,
                      },
                    ]
                  : []),
                ...(getQuery.data.eventType === 'Corporate'
                  ? [
                      {
                        name: 'companyName',
                        label: 'Company Name',
                        type: 'text',
                        value: getQuery.data.companyName,
                      },
                    ]
                  : []),
                ...(getQuery.data.eventType === 'Private'
                  ? [
                      {
                        name: 'typeOfEvent',
                        label: 'Type Of Event',
                        type: 'text',
                        value: getQuery.data.typeOfEvent,
                      },
                    ]
                  : []),
                {
                  name: 'date',
                  label: 'Date',
                  type: 'date',
                  value: getQuery.data.date,
                },
                {
                  name: 'endDate',
                  label: 'End Date',
                  type: 'date',
                  value: getQuery.data.endDate,
                },
                {
                  name: 'start',
                  label: 'Start Time',
                  type: 'time',
                  value: getQuery.data.startHour
                    ? `${stringUtils.twoDigit(
                        getQuery.data.startHour
                      )}:${stringUtils.twoDigit(getQuery.data.startMinute)}`
                    : null,
                },
                {
                  name: 'end',
                  label: 'End Time',
                  type: 'time',
                  value: getQuery.data.endHour
                    ? `${stringUtils.twoDigit(
                        getQuery.data.endHour
                      )}:${stringUtils.twoDigit(getQuery.data.endMinute)}`
                    : null,
                },
                {
                  name: 'location',
                  label: 'Location',
                  type: 'text',
                  value: getQuery.data.location,
                },
                {
                  name: 'locationLink',
                  label: 'Location Link',
                  type: 'text',
                  value: getQuery.data.locationLink,
                },
                {
                  name: 'indoors',
                  label: 'Indoors',
                  type: 'boolean',
                  value: getQuery.data.indoors,
                },
                {
                  name: 'outdoors',
                  label: 'Outdoors',
                  type: 'boolean',
                  value: getQuery.data.outdoors,
                },
                {
                  name: 'numberOfGuests',
                  label: 'Number Of Guests',
                  type: 'number',
                  value: getQuery.data.numberOfGuests,
                },
                {
                  name: 'numberOfBars',
                  label: 'Number Of Bars',
                  type: 'number',
                  value: getQuery.data.numberOfBars,
                },
                {
                  name: 'softDrinks',
                  label: 'Soft Drinks',
                  type: 'boolean',
                  value: getQuery.data.softDrinks,
                },
                {
                  name: 'redBull',
                  label: 'Red Bull',
                  type: 'boolean',
                  value: getQuery.data.redBull,
                },
                {
                  name: 'ice',
                  label: 'Ice',
                  type: 'boolean',
                  value: getQuery.data.ice,
                },
                {
                  name: 'barRental',
                  label: 'Bar Rental',
                  type: 'boolean',
                  value: getQuery.data.barRental,
                },
                {
                  name: 'waiters',
                  label: 'Waiters',
                  type: 'boolean',
                  value: getQuery.data.waiters,
                },
                {
                  name: 'glasses',
                  label: 'Glasses',
                  type: 'boolean',
                  value: getQuery.data.glasses,
                },
                {
                  name: 'coatMan',
                  label: 'Coat Man',
                  type: 'boolean',
                  value: getQuery.data.coatMan,
                },
                {
                  name: 'shotsPackage',
                  label: 'Shots Package',
                  type: 'boolean',
                  value: getQuery.data.shotsPackage,
                },
                {
                  name: 'contactName',
                  label: 'Contact Name',
                  type: 'text',
                  value: getQuery.data.contactName,
                },
                {
                  name: 'contactEmail',
                  label: 'Contact Email',
                  type: 'email',
                  value: getQuery.data.contactEmail,
                },
                {
                  name: 'contactNumber',
                  label: 'Contact Number',
                  type: 'text',
                  value: getQuery.data.contactNumber,
                },
                {
                  name: 'planner',
                  label: 'Planner',
                  type: 'text',
                  value: getQuery.data.planner,
                },
                {
                  name: 'tastingSessionDate',
                  label: 'Tasting Session Date',
                  type: 'date',
                  value: getQuery.data.tastingSessionDate,
                },
                {
                  name: 'followUp',
                  label: 'Follow Up',
                  type: 'text',
                  value: getQuery.data.followUp,
                },
                {
                  name: 'status',
                  label: 'Status',
                  type: 'select',
                  options: types.eventStatusTypes.map((eventStatusType) => ({
                    label: eventStatusType,
                    value: eventStatusType,
                  })),
                  value: getQuery.data.status,
                },
                {
                  name: 'reason',
                  label: 'Reason',
                  type: 'text',
                  value: getQuery.data.reason,
                },
                {
                  name: 'importance',
                  label: 'Importance',
                  type: 'select',
                  options: types.colors.map((color) => ({
                    label: color,
                    value: color,
                  })),
                  value: getQuery.data.importance,
                },
                {
                  name: 'users',
                  label: 'Users',
                  type: 'autocompleteMultiple',
                  queryKey: 'users',
                  getApi: userService.getUsers,
                  options: { role: 'editor' },
                  keys: { name: 'userName', _id: 'userId' },
                  value: getQuery.data.users,
                },
                {
                  name: 'note',
                  label: 'Note',
                  type: 'text',
                  value: getQuery.data.note,
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
            buttonColor: 'secondary',
            onSubmit: () => deleteMutation.mutate(),
            form: {
              title: `${translate.Delete} ${translate[singleCollectionName]}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
          },
          {
            buttonLabel: `${translate.Return} ${translate.Event}`,
            buttonColor: 'secondary',
            onSubmit: () => {
              returnEventMutation.mutate(params.id)
              navigate(`/quotations/${params.id}`)
            },
            form: {
              title: `${translate.Return} ${translate.Event}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to create this']} ${translate.Quotation}`,
          },
          {
            buttonLabel: `${translate.Update} ${translate['Tasting Session']}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              const newBody = objectsHelper.compare(
                getQuery.data.tastingSession || {},
                args
              )
              if (Object.keys(newBody).length)
                return updateMutation.mutate({ tastingSession: newBody })
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Update} ${translate['Tasting Session']}`,
              inputs: [
                {
                  name: 'mrXName',
                  label: 'Mr X Name',
                  type: 'text',
                  value: getQuery.data.tastingSession?.mrXName,
                },
                {
                  name: 'glass',
                  label: 'Glass',
                  type: 'text',
                  value: getQuery.data.tastingSession?.glass,
                },
                {
                  name: 'cocktails',
                  label: 'Cocktails',
                  type: 'text',
                  value: getQuery.data.tastingSession?.cocktails,
                },
                {
                  name: 'preWedding',
                  label: 'Pre Wedding',
                  type: 'text',
                  value: getQuery.data.tastingSession?.preWedding,
                },
                {
                  name: 'accessoriesAndFrame',
                  label: 'Accessories And Frame',
                  type: 'text',
                  value: getQuery.data.tastingSession?.accessoriesAndFrame,
                },
                {
                  name: 'uniform',
                  label: 'Uniform',
                  type: 'text',
                  value: getQuery.data.tastingSession?.uniform,
                },
                {
                  name: 'bottles',
                  label: 'Bottles',
                  type: 'text',
                  value: getQuery.data.tastingSession?.bottles,
                },
                {
                  name: 'cakeShots',
                  label: 'Cake Shots',
                  type: 'text',
                  value: getQuery.data.tastingSession?.cakeShots,
                },
                {
                  name: 'schedule',
                  label: 'Schedule',
                  type: 'text',
                  value: getQuery.data.tastingSession?.schedule,
                },
                {
                  name: 'addOns',
                  label: 'Add Ons',
                  type: 'text',
                  value: getQuery.data.tastingSession?.addOns,
                },
                {
                  name: 'note',
                  label: 'Note',
                  type: 'text',
                  value: getQuery.data.tastingSession?.note,
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Add} ${translate.Quotation}`,
            buttonColor: 'primary',
            onSubmit: (args) => updateMutation.mutate(args),
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Add} ${translate.Quotation}`,
              inputs: [
                {
                  name: 'budget',
                  label: 'Budget',
                  type: 'number',
                  value: getQuery.data.budget,
                },
                {
                  name: 'quotation',
                  label: 'Quotation',
                  type: 'autocompleteMultiple',
                  queryKey: 'notes',
                  getApi: noteService.getNotes,
                  options: { extras: 'false' },
                  keys: { name: 'name', note: 'note' },
                  value: getQuery.data.quotation,
                },
                {
                  name: 'extras',
                  label: 'Extras',
                  type: 'autocompleteMultiple',
                  queryKey: 'notes',
                  getApi: noteService.getNotes,
                  options: { extras: 'true' },
                  keys: { name: 'name', note: 'note', price: 'price' },
                  value: getQuery.data.extras,
                },
                {
                  name: 'summary',
                  label: 'Summary',
                  type: 'text',
                  value: getQuery.data.summary,
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Generate} ${translate.Quotation}`,
            buttonColor: 'primary',
            onSubmit: () =>
              eventService
                .generateQuotation(params.id)
                .then((response) => {
                  const file = new Blob([response], { type: 'application/pdf' })
                  const fileURL = URL.createObjectURL(file)
                  const link = document.createElement('a')
                  link.href = fileURL
                  link.download = `${getQuery.data?.name} Quotation.pdf`
                  link.click()
                })
                .catch(() =>
                  enqueueSnackbar(
                    `${translate[singleCollectionName]} ${translate['could not be']} ${translate.generated}: ${translate['Missing Information']}`,
                    { variant: 'error', TransitionProps: { direction: 'left' } }
                  )
                ),
            form: {
              title: `${translate.Generate} ${translate.Quotation}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to create this']} ${translate.Quotation}`,
          },
          {
            buttonLabel: `${translate.Add} ${translate.Multiple} ${translate.Staff}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              args.employees.forEach((employee) => {
                addStaffMutation.mutate(employee)
              })
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Add} ${translate.Multiple} ${translate.Staff}`,
              inputs: [
                {
                  name: 'employees',
                  label: 'Employees',
                  type: 'employeesTable',
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Generate} ${translate.Report}`,
            buttonColor: 'primary',
            onSubmit: () =>
              eventService
                .generateReport(params.id)
                .then((response) => {
                  const file = new Blob([response], { type: 'application/pdf' })
                  const fileURL = URL.createObjectURL(file)
                  const link = document.createElement('a')
                  link.href = fileURL
                  link.download = `${getQuery.data.name} Report.pdf`
                  link.click()
                })
                .catch(() =>
                  enqueueSnackbar(
                    `${translate[singleCollectionName]} ${translate['could not be']} ${translate.generated}: ${translate['Missing Information']}`,
                    { variant: 'error', TransitionProps: { direction: 'left' } }
                  )
                ),
            form: {
              title: `${translate.Generate} ${translate.Report}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to create this']} ${translate.Report}`,
          },
          {
            buttonLabel: `${translate.Add} ${translate.Expense}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              args.eventId = getQuery.data._id
              args.eventName = getQuery.data.name
              args.type = 'events'
              args.typeId = '66328d0fc2984641f66417dc'
              args.typeName = 'Wedding Expenses'
              createExpenseMutation.mutate(args)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Add} ${translate.Expense}`,
              inputs: [
                {
                  name: 'itemName',
                  label: 'Item',
                  type: 'autocomplete',
                  required: true,
                  queryKey: 'items',
                  getApi: itemService.getItems,
                  keys: { _id: 'itemId', name: 'itemName' },
                  value: {
                    itemId: '66328d44c2984641f66417df',
                    itemName: 'Other',
                  },
                  options: { typeId: '66328d0fc2984641f66417dc' },
                },
                {
                  name: 'value',
                  label: 'Value',
                  type: 'number',
                  required: true,
                },
                {
                  name: 'reason',
                  label: 'Reason',
                  type: 'text',
                },
                {
                  name: 'check',
                  label: 'Check',
                  type: 'boolean',
                },
                {
                  name: 'note',
                  label: 'Note',
                  type: 'text',
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Add} ${translate.Income}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              args.eventId = getQuery.data._id
              args.eventName = getQuery.data.name
              args.type = 'events'
              args.typeId = '662aea3e3fa9741aaef6b1fc'
              args.typeName = 'Incomes'
              args.itemId = '662aea633fa9741aaef6b1fd'
              args.itemName = 'Events'
              createIncomeMutation.mutate(args)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Add} ${translate.Income}`,
              inputs: [
                {
                  name: 'value',
                  label: 'Value',
                  type: 'number',
                  required: true,
                },
                {
                  name: 'reason',
                  label: 'Reason',
                  type: 'text',
                },
                {
                  name: 'check',
                  label: 'Check',
                  type: 'boolean',
                },
                {
                  name: 'note',
                  label: 'Note',
                  type: 'text',
                },
              ],
            },
          },
        ]}
        title={singleCollectionName}
        getQuery={getQuery}
        many={false}
        staffActions={[
          {
            onClick: (event, row) => removeStaffMutation.mutate(row.employeeId),
            icon: 'delete',
            tooltip: 'Remove Staff',
          },
        ]}
        notToShow={['quotation', 'extras', 'summary', 'users']}
      />
      <Guard condition={!getQuery.data.parentId}>
        <Events parentId={getQuery.data._id} />
      </Guard>
      <Expenses eventId={getQuery.data._id} eventName={getQuery.data.name} />
      <RoleBasedGuard key={0} permitedRoles={['owner']}>
        <Incomes eventId={getQuery.data._id} eventName={getQuery.data.name} />
      </RoleBasedGuard>
    </>
  )
}
export default Event
