import axios from '../_helpers/axios'

const route = 'Employees'.toLowerCase()
const createEmployee = async (createBody) =>
  axios.post(`v1/${route}`, createBody)
const getEmployees = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getEmployee = async (id) => axios.get(`v1/${route}/${id}`)
const updateEmployee = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteEmployee = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createEmployee,
  getEmployees,
  getEmployee,
  updateEmployee,
  deleteEmployee,
}
