import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import reactQuery from '../../../_helpers/reactQuery'
import stringUtils from '../../../_helpers/stringUtils'
import translate from '../../../locales/en'

function AutoComplete(props) {
  const [searchValue, setSearchValue] = useState()
  const [debouncedSearch] = useDebounce(searchValue, 500)
  const getQuery = reactQuery.getQuery(
    props.queryKey,
    { ...(debouncedSearch && { search: debouncedSearch }), ...props.options },
    props.getApi
  )
  return (
    <Autocomplete
      loading={getQuery.isLoading}
      disablePortal
      fullWidth
      options={getQuery.data?.documents || []}
      getOptionLabel={(option) => `${option.name || option[props.name] || ''}`}
      isOptionEqualToValue={(option, v) =>
        (option.name && v.name && option.name === v.name) ||
        (option[props.name] &&
          v[props.name] &&
          option[props.name] === v[props.name]) ||
        (option.name && v[props.name] && option.name === v[props.name]) ||
        (option[props.name] && v.name && option[props.name] === v.name)
      }
      onChange={(_, newValue) => {
        if (newValue) props.onChange(JSON.stringify(newValue))
        else props.onChange(JSON.stringify({}))
      }}
      onInputChange={(_, newValue, reason) => {
        if (reason !== 'reset') setSearchValue(newValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          label={translate[props.label]}
          type="text"
          fullWidth
          InputLabelProps={{ shrink: true }}
          key={`${props.index}TextField`}
          autoFocus={props.index === 0}
          autoComplete="new-password"
          required={props.required}
          error={props.required && !stringUtils.IsJsonString(props.value)}
          {...(props.required &&
            !stringUtils.IsJsonString(props.value) && {
              helperText: `${translate[props.label]} ${
                translate['is required']
              }`,
            })}
        />
      )}
      {...(props.value && {
        value: stringUtils.IsJsonString(props.value) || { name: props.value },
      })}
      key={`${props.index}Autocomplete`}
    />
  )
}

export default AutoComplete
