import { ViewState } from '@devexpress/dx-react-scheduler'
import {
  Appointments,
  DateNavigator,
  MonthView,
  Scheduler,
  TodayButton,
  Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import styled from '@mui/material/styles/styled'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import reactQuery from '../../_helpers/reactQuery'
import { eventService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Dialog from '../Abstract/Dialog'
import Guard from '../Guards/Guard'

const classes = {
  toolbarRoot: `toolbarRoot`,
  progress: `progress`,
}

const StyledDiv = styled('div')({
  [`&.${classes.toolbarRoot}`]: {
    position: 'relative',
  },
})

const StyledLinearProgress = styled(LinearProgress)(() => ({
  [`&.${classes.progress}`]: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
}))

function ToolbarWithLoading({ children, ...restProps }) {
  return (
    <StyledDiv className={classes.toolbarRoot}>
      <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
      <StyledLinearProgress className={classes.progress} />
    </StyledDiv>
  )
}

function Appointment({ children, style, ...restProps }) {
  const navigate = useNavigate()
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        backgroundColor: restProps.data?.color,
        borderRadius: '8px',
      }}
      onClick={() => navigate(`/events/${restProps.data?.id}`)}
    >
      {children}
    </Appointments.Appointment>
  )
}

export default function () {
  const [open, setOpen] = useState(false)
  const [body, setBody] = useState({})
  const [date, setDate] = useState(new Date())
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.toString() !== '') {
      const searchParamsObject = Object.fromEntries([...searchParams])
      setDate(new Date(searchParamsObject.date))
    }
  }, [])
  const collectionName = 'Events'
  const queryKey = collectionName.toLowerCase()
  const getQuery = reactQuery.getQuery(
    queryKey,
    { month: date.getMonth() + 1, year: date.getFullYear(), status: 'All' },
    eventService.getEvents
  )
  const singleCollectionName = 'Event'
  const createMutation = reactQuery.createMutation(
    eventService.createEvent,
    singleCollectionName,
    queryKey
  )
  const TimeTableCell = useCallback(
    // eslint-disable-next-line react/no-unstable-nested-components
    memo(({ ...restProps }) => (
      <MonthView.TimeTableCell
        {...restProps}
        onDoubleClick={() => {
          setBody({ date: restProps.startDate.toLocaleDateString('en-CA') })
          setOpen(true)
        }}
      />
    )),
    []
  )
  return (
    <>
      <Guard condition={open}>
        <Dialog
          open={open}
          close={() => {
            setOpen(false)
            setBody({})
          }}
          inputs={[
            {
              name: 'eventType',
              label: 'Event Type',
              type: 'select',
              options: types.eventsTypes.map((eventType) => ({
                label: eventType,
                value: eventType,
              })),
              required: true,
            },
            ...(body.eventType === 'Wedding'
              ? [
                  {
                    name: 'groomName',
                    label: 'Groom Name',
                    type: 'text',
                    required: true,
                  },
                  {
                    name: 'brideName',
                    label: 'Bride Name',
                    type: 'text',
                  },
                ]
              : []),
            ...(body.eventType === 'Corporate'
              ? [
                  {
                    name: 'companyName',
                    label: 'Company Name',
                    type: 'text',
                    required: true,
                  },
                ]
              : []),
            ...(body.eventType === 'Private'
              ? [
                  {
                    name: 'typeOfEvent',
                    label: 'Type Of Event',
                    type: 'text',
                    required: true,
                  },
                ]
              : []),
            {
              name: 'date',
              label: 'Date',
              type: 'date',
              required: true,
              value: body.date,
              disabled: true,
            },
            {
              name: 'endDate',
              label: 'End Date',
              type: 'date',
            },
            {
              name: 'start',
              label: 'Start Time',
              type: 'time',
            },
            {
              name: 'end',
              label: 'End Time',
              type: 'time',
            },
            {
              name: 'location',
              label: 'Location',
              type: 'text',
            },
            {
              name: 'locationLink',
              label: 'Location Link',
              type: 'text',
            },
            {
              name: 'indoors',
              label: 'Indoors',
              type: 'boolean',
            },
            {
              name: 'outdoors',
              label: 'Outdoors',
              type: 'boolean',
            },
            {
              name: 'numberOfGuests',
              label: 'Number Of Guests',
              type: 'number',
            },
            {
              name: 'numberOfBars',
              label: 'Number Of Bars',
              type: 'number',
            },
            {
              name: 'softDrinks',
              label: 'Soft Drinks',
              type: 'boolean',
            },
            {
              name: 'redBull',
              label: 'Red Bull',
              type: 'boolean',
            },
            {
              name: 'ice',
              label: 'Ice',
              type: 'boolean',
            },
            {
              name: 'barRental',
              label: 'Bar Rental',
              type: 'boolean',
            },
            {
              name: 'glasses',
              label: 'Glasses',
              type: 'boolean',
            },
            {
              name: 'waiters',
              label: 'Waiters',
              type: 'boolean',
            },
            {
              name: 'coatMan',
              label: 'Coat Man',
              type: 'boolean',
            },
            {
              name: 'shotsPackage',
              label: 'Shots Package',
              type: 'boolean',
            },
            {
              name: 'contactName',
              label: 'Contact Name',
              type: 'text',
            },
            {
              name: 'contactEmail',
              label: 'Contact Email',
              type: 'email',
            },
            {
              name: 'contactNumber',
              label: 'Contact Number',
              type: 'text',
            },
            {
              name: 'planner',
              label: 'Planner',
              type: 'text',
            },
            {
              name: 'tastingSessionDate',
              label: 'Tasting Session Date',
              type: 'date',
            },
            {
              name: 'followUp',
              label: 'Follow Up',
              type: 'text',
            },
            {
              name: 'status',
              label: 'Status',
              type: 'select',
              options: types.eventStatusTypes.map((eventStatusType) => ({
                label: eventStatusType,
                value: eventStatusType,
              })),
            },
            {
              name: 'reason',
              label: 'Reason',
              type: 'text',
            },
            {
              name: 'importance',
              label: 'Importance',
              type: 'select',
              options: types.colors.map((color) => ({
                label: color,
                value: color,
              })),
            },
            {
              name: 'note',
              label: 'Note',
              type: 'text',
            },
          ]}
          title={`${translate.Add} ${translate[singleCollectionName]}`}
          onSubmit={(args) => {
            setOpen(false)
            const { start, end } = args
            delete args.start
            delete args.end
            if (start) [args.startHour, args.startMinute] = start.split(':')
            if (end) [args.endHour, args.endMinute] = end.split(':')
            createMutation.mutate(args)
            setBody({})
          }}
          body={body}
          setBody={setBody}
        />
      </Guard>
      <Paper style={{ borderRadius: '8px' }}>
        <Scheduler
          data={getQuery.data?.documents.map((event) => {
            if (event._id === '643a8917c080330baf1667af')
              return {
                id: '643a8917c080330baf1667af',
                title: 'Aswan Long Event',
                color: 'red',
                startDate: '2023-04-29T00:00:00.000Z',
                endDate: '2023-05-06T00:00:00.000Z',
              }
            const e = new Date(event.endDate)
            const endDate = new Date(e.setHours(e.getHours() + 1))
            return {
              id: event._id,
              title: event.name,
              color: event.importance,
              startDate: new Date(event.date),
              endDate,
            }
          })}
        >
          <ViewState
            currentDate={date}
            onCurrentDateChange={(d) => {
              setDate(d)
              setSearchParams({
                date: d.toLocaleDateString(),
              })
            }}
          />
          <MonthView timeTableCellComponent={TimeTableCell} />
          <Toolbar
            {...(getQuery.isLoading
              ? { rootComponent: ToolbarWithLoading }
              : null)}
          />
          <DateNavigator />
          <TodayButton />
          <Appointments appointmentComponent={Appointment} />
        </Scheduler>
      </Paper>
    </>
  )
}
