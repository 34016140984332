import axios from '../_helpers/axios'

const route = 'Quotations'.toLowerCase()
const createQuotation = async (createBody) =>
  axios.post(`v1/${route}`, createBody)
const getQuotations = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getQuotation = async (id) => axios.get(`v1/${route}/${id}`)
const updateQuotation = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteQuotation = async (id) => axios.delete(`v1/${route}/${id}`)
const createEvent = async (id) => axios.put(`v1/${route}/${id}`)
const generateQuotation = async (id) =>
  axios.get(`v1/${route}/generate/${id}`, { responseType: 'blob' })
export default {
  createQuotation,
  getQuotations,
  getQuotation,
  updateQuotation,
  deleteQuotation,
  createEvent,
  generateQuotation,
}
