import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import {
  expenseService,
  itemService,
  supplierService,
  typeService,
} from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Expense = function () {
  const collectionName = 'Expenses'
  const singleCollectionName = 'Expense'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    expenseService.getExpense
  )
  const updateMutation = reactQuery.updateMutation(
    expenseService.updateExpense,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    expenseService.deleteExpense,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  return (
    <Document
      actions={[
        {
          buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const newBody = objectsHelper.compare(getQuery.data, args)
            if (Object.keys(newBody).length)
              return updateMutation.mutate(newBody)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Update} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'typeName',
                label: 'Type',
                type: 'autocomplete',
                required: true,
                queryKey: 'types',
                getApi: typeService.getTypes,
                keys: { _id: 'typeId', name: 'typeName' },
                value: {
                  typeId: getQuery.data?.typeId,
                  typeName: getQuery.data?.typeName,
                },
                options: { type: 'expenses' },
              },
              {
                name: 'itemName',
                label: 'Item',
                type: 'autocomplete',
                required: true,
                queryKey: 'items',
                getApi: itemService.getItems,
                keys: { _id: 'itemId', name: 'itemName' },
                value: {
                  itemId: getQuery.data?.itemId,
                  itemName: getQuery.data?.itemName,
                },
                options: { typeId: body.typeId },
              },
              {
                name: 'supplierName',
                label: 'Supplier',
                type: 'autocomplete',
                queryKey: 'suppliers',
                getApi: supplierService.getSuppliers,
                keys: { _id: 'supplierId', name: 'supplierName' },
                value: {
                  supplierId: getQuery.data?.supplierId,
                  supplierName: getQuery.data?.supplierName,
                },
              },
              {
                name: 'value',
                label: 'Value',
                type: 'number',
                value: getQuery.data?.value,
              },
              {
                name: 'reason',
                label: 'Reason',
                type: 'text',
                value: getQuery.data?.reason,
              },
              {
                name: 'check',
                label: 'Check',
                type: 'boolean',
                value: getQuery.data?.check,
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
                value: getQuery.data?.note,
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
          buttonColor: 'secondary',
          onSubmit: () => deleteMutation.mutate(),
          form: {
            title: `${translate.Delete} ${translate[singleCollectionName]}`,
          },
          confirm: true,
          info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
        },
      ]}
      title={singleCollectionName}
      getQuery={getQuery}
      many={false}
    />
  )
}
export default Expense
