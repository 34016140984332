import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { itemService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Item = function ({ typeName, typeId }) {
  const collectionName = 'Items'
  const singleCollectionName = 'Item'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = { typeId }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    itemService.getItems
  )
  const createMutation = reactQuery.createMutation(
    itemService.createItem,
    singleCollectionName,
    queryKey,
    [['type', { id: typeId }]]
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) =>
            createMutation.mutate({ ...args, typeId, typeName }),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      navigate={[queryKey, '_id']}
    />
  )
}
export default Item
