import axios from '../_helpers/axios'

const route = 'Suppliers'.toLowerCase()
const createSupplier = async (createBody) =>
  axios.post(`v1/${route}`, createBody)
const getSuppliers = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getSupplier = async (id) => axios.get(`v1/${route}/${id}`)
const updateSupplier = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteSupplier = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createSupplier,
  getSuppliers,
  getSupplier,
  updateSupplier,
  deleteSupplier,
}
