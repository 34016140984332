import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { noteService } from '../../_services'
import translate from '../../locales/en'
import Collection from '../Abstract/Document'

const Note = function () {
  const collectionName = 'Notes'
  const singleCollectionName = 'Note'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    noteService.getNotes
  )
  const createMutation = reactQuery.createMutation(
    noteService.createNote,
    singleCollectionName,
    queryKey
  )
  return (
    <Collection
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
                required: true,
              },
              {
                name: 'extras',
                label: 'Extras',
                type: 'boolean',
              },
              ...(body.extras
                ? [
                    {
                      name: 'price',
                      label: 'Price',
                      type: 'number',
                      required: true,
                    },
                  ]
                : []),
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Note
