import axios from '../_helpers/axios'

const route = 'Events'.toLowerCase()
const createEvent = async (createBody) => axios.post(`v1/${route}`, createBody)
const getEvents = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getEvent = async (id) => axios.get(`v1/${route}/${id}`)
const updateEvent = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteEvent = async (id) => axios.delete(`v1/${route}/${id}`)
const addStaff = async (id, createBody) =>
  axios.post(`v1/${route}/staff/${id}`, createBody)
const removeStaff = async (id, subId) =>
  axios.delete(`v1/${route}/staff/${id}?id=${subId}`)
const generateReport = async (id) =>
  axios.get(`v1/${route}/generate/${id}`, { responseType: 'blob' })
const generateQuotation = async (id) =>
  axios.delete(`v1/${route}/generate/${id}`, { responseType: 'blob' })
const returnEvent = async (id) => axios.delete(`v1/${route}/return-event/${id}`)
export default {
  createEvent,
  getEvents,
  getEvent,
  updateEvent,
  deleteEvent,
  addStaff,
  removeStaff,
  generateReport,
  generateQuotation,
  returnEvent,
}
