import MaterialTable from '@material-table/core'
// import { MTableBody } from '@material-table/core'
// import { TableCell, TableFooter, TableRow } from '@mui/material'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { employeeService } from '../../../_services'
import translate from '../../../locales/en'
import AutoComplete from '../TableInputs/Autocomplete'
import Number from '../TableInputs/Number'
import Text from '../TableInputs/Text'

const EmployeesTable = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    isValid: () => true,
  }))
  const [data, setData] = useState(props.value || [])
  useEffect(() => {
    props.setIsValids((s) => {
      s[props.index] = true
      return s
    })
    props.setIsValid(
      props.isValids.reduce((isAllValid, key, i) => {
        if (i === props.index) return isAllValid && true
        return isAllValid && key
      }, true)
    )
    props.setBody((s) => ({ ...s, [props.name]: data }))
  }, [data])
  return (
    <div className="tableComponent">
      <MaterialTable
        localization={{ body: { emptyDataSourceMessage: '' } }}
        title={translate[props.label]}
        columns={[
          {
            title: translate['Employee Name'],
            field: 'employeeName',
            editComponent: (props) => (
              <AutoComplete
                value={props.value}
                onChange={(v) => props.onChange(v)}
                queryKey="employees"
                options={{}}
                getApi={employeeService.getEmployees}
                name="employeeName"
                label="Employee"
                required
                index={
                  props.index +
                  1 +
                  Object.keys(data[0] || {}).length * data.length
                }
              />
            ),
            editable: 'onAdd',
          },
          {
            title: translate.Role,
            field: 'role',
            editComponent: (props) => (
              <Text
                value={props.value}
                onChange={(v) => props.onChange(v)}
                label="Role"
                required
                index={
                  props.index +
                  2 +
                  Object.keys(data[0] || {}).length * data.length
                }
              />
            ),
          },
          {
            title: translate.Rating,
            field: 'rating',
            editComponent: (props) => (
              <Number
                value={props.value}
                onChange={(v) => props.onChange(v)}
                label="Rating"
                index={
                  props.index +
                  3 +
                  Object.keys(data[0] || {}).length * data.length
                }
                max={5}
              />
            ),
          },
          {
            title: translate.Comment,
            field: 'comment',
            editComponent: (props) => (
              <Text
                value={props.value}
                onChange={(v) => props.onChange(v)}
                label="Comment"
                index={
                  props.index +
                  4 +
                  Object.keys(data[0] || {}).length * data.length
                }
              />
            ),
          },
        ]}
        data={data}
        style={{
          overflowX: 'hidden',
          width: '100%',
          marginTop: '5px',
          marginBottom: '5px',
        }}
        options={{ paging: false, search: false, actionsColumnIndex: -1 }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              const employee = JSON.parse(newData.employeeName)
              newData.employeeName = employee.name
              newData.employeeId = employee._id
              if (!newData.rating) newData.rating = null
              newData.id = `uuid-${Math.random() * 10000000}`
              setData([...data, newData])
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              if (!newData.value) newData.value = 1
              const dataUpdate = [...data]
              const target = dataUpdate.find((el) => el.id === oldData.id)
              const index = dataUpdate.indexOf(target)
              dataUpdate[index] = newData
              setData(dataUpdate)
              resolve()
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              const dataDelete = data.filter((el) => el.id !== oldData.id)
              setData(dataDelete)
              resolve()
            }),
        }}
      />
    </div>
  )
})

export default EmployeesTable
