import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Calendar from '../Collections/Calendar'
import Cashflow from '../Collections/Cashflow'
import ContactUss from '../Collections/ContactUss'
import Employees from '../Collections/Employees'
import Events from '../Collections/Events'
import Expenses from '../Collections/Expenses'
import Incomes from '../Collections/Incomes'
import Items from '../Collections/Items'
import Notes from '../Collections/Notes'
import Paychecks from '../Collections/Paychecks'
import Quotations from '../Collections/Quotations'
import Suppliers from '../Collections/Suppliers'
import Types from '../Collections/Types'
import Users from '../Collections/Users'
import ContactUs from '../Documents/ContactUs'
import Employee from '../Documents/Employee'
import Event from '../Documents/Event'
import Expense from '../Documents/Expense'
import Income from '../Documents/Income'
import Item from '../Documents/Item'
import Note from '../Documents/Note'
import Paycheck from '../Documents/Paycheck'
import Profile from '../Documents/Profile'
import Quotation from '../Documents/Quotation'
import Supplier from '../Documents/Supplier'
import Type from '../Documents/Type'
import User from '../Documents/User'
import RoleBasedGuard from '../Guards/RoleBasedGuard'

const MainRoutes = function () {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/calendar" />} />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/contactUs" element={<ContactUss />} />
      <Route path="/contactUs/:id" element={<ContactUs />} />
      <Route path="/employees" element={<Employees />} />
      <Route path="/employees/:id" element={<Employee />} />
      <Route path="/events" element={<Events />} />
      <Route path="/events/:id" element={<Event />} />
      <Route path="/expenses" element={<Expenses />} />
      <Route path="/expenses/:id" element={<Expense />} />
      <Route
        path="/incomes"
        element={
          <RoleBasedGuard key={2} permitedRoles={['owner']}>
            <Incomes />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/incomes/:id"
        element={
          <RoleBasedGuard key={3} permitedRoles={['owner']}>
            <Income />
          </RoleBasedGuard>
        }
      />
      <Route path="/items" element={<Items />} />
      <Route path="/items/:id" element={<Item />} />
      <Route path="/notes" element={<Notes />} />
      <Route path="/notes/:id" element={<Note />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/quotations" element={<Quotations />} />
      <Route path="/quotations/:id" element={<Quotation />} />
      <Route path="/suppliers" element={<Suppliers />} />
      <Route path="/suppliers/:id" element={<Supplier />} />
      <Route path="/types" element={<Types />} />
      <Route path="/types/:id" element={<Type />} />
      <Route
        path="/users"
        element={
          <RoleBasedGuard key={4} permitedRoles={['owner']}>
            <Users />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/users/:userEmail"
        element={
          <RoleBasedGuard key={5} permitedRoles={['owner']}>
            <User />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/cashflow"
        element={
          <RoleBasedGuard key={1} permitedRoles={['owner']}>
            <Cashflow />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/paycheck"
        element={
          <RoleBasedGuard key={6} permitedRoles={['owner']}>
            <Paychecks />
          </RoleBasedGuard>
        }
      />
      <Route
        path="/paycheck/:id"
        element={
          <RoleBasedGuard key={7} permitedRoles={['owner']}>
            <Paycheck />
          </RoleBasedGuard>
        }
      />
      <Route path="*" element={<Calendar />} />
    </Routes>
  )
}
export default MainRoutes
