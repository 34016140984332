const userTypes = ['owner', 'admin', 'editor']
const eventsTypes = ['Wedding', 'Corporate', 'Private']
const eventStatusTypes = [
  'OnCall',
  'Tasted',
  'Confirm',
  'Cancel',
  'Done',
  'Off',
]
const colors = [
  '',
  'cyan',
  'puprle',
  'yellow',
  'red',
  'green',
  'blue',
  'white',
  'gray',
  'black',
]
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const types = ['incomes', 'expenses']
const boolean = ['true', 'false']
const owners = ['Asser', 'Sherif']
export default {
  userTypes,
  eventsTypes,
  eventStatusTypes,
  colors,
  months,
  types,
  boolean,
  owners,
}
