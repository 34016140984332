import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { incomeService, itemService, typeService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Income = function ({ eventId, eventName }) {
  const collectionName = 'Incomes'
  const singleCollectionName = 'Income'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = { ...(eventId && { eventId }) }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    incomeService.getIncomes
  )
  const createMutation = reactQuery.createMutation(
    incomeService.createIncome,
    singleCollectionName,
    queryKey,
    [['event', { id: eventId }]]
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams(
              objectsHelper.cleanEmpty({
                ...Object.fromEntries([...searchParams]),
                ...args,
                query: collectionName,
              })
            ),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'month',
                label: 'Month',
                type: 'number',
                value: queryParams.month,
                max: 12,
              },
              {
                name: 'year',
                label: 'Year',
                type: 'number',
                value: queryParams.year,
              },
              {
                name: 'typeName',
                label: 'Type',
                type: 'autocomplete',
                queryKey: 'types',
                getApi: typeService.getTypes,
                keys: { _id: 'typeId', name: 'typeName' },
                value: {
                  typeId: queryParams.typeId,
                  typeName: queryParams.typeName,
                },
                options: { type: 'incomes' },
              },
              {
                name: 'itemName',
                label: 'Item',
                type: 'autocomplete',
                queryKey: 'items',
                getApi: itemService.getItems,
                keys: { _id: 'itemId', name: 'itemName' },
                value: {
                  itemId: queryParams.itemId,
                  itemName: queryParams.itemName,
                },
                options: { typeId: body.typeId },
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            args.type = 'other'
            if (eventId) {
              args.eventId = eventId
              args.eventName = eventName
              args.type = 'events'
              args.typeId = '662aea3e3fa9741aaef6b1fc'
              args.typeName = 'Incomes'
              args.itemId = '662aea633fa9741aaef6b1fd'
              args.itemName = 'Events'
            }
            createMutation.mutate(args)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              ...(!eventId
                ? [
                    {
                      name: 'typeName',
                      label: 'Type',
                      type: 'autocomplete',
                      required: true,
                      queryKey: 'types',
                      getApi: typeService.getTypes,
                      keys: { _id: 'typeId', name: 'typeName' },
                      value: {},
                      options: { type: 'incomes' },
                    },
                    {
                      name: 'itemName',
                      label: 'Item',
                      type: 'autocomplete',
                      required: true,
                      queryKey: 'items',
                      getApi: itemService.getItems,
                      keys: { _id: 'itemId', name: 'itemName' },
                      value: {},
                      options: { typeId: body.typeId },
                    },
                  ]
                : []),
              {
                name: 'value',
                label: 'Value',
                type: 'number',
                required: true,
              },
              {
                name: 'reason',
                label: 'Reason',
                type: 'text',
              },
              {
                name: 'check',
                label: 'Check',
                type: 'boolean',
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      navigate={eventId ? [queryKey, '_id'] : null}
    />
  )
}
export default Income
