import Autocomplete from './Autocomplete'
import AutocompleteMultiple from './AutocompleteMultiple'
import AutocompleteMultiple2 from './AutocompleteMultiple2'
import Boolean from './Boolean'
import Date from './Date'
import DateTime from './DateTime'
import Email from './Email'
import EmployeesTable from './EmployeesTable'
import ExpensesTable from './ExpensesTable'
import File from './File'
import Number from './Number'
import Password from './Password'
import Select from './Select'
import Text from './Text'
import Time from './Time'

const getInput = (type) => {
  switch (type) {
    case 'autocomplete':
      return Autocomplete
    case 'autocompleteMultiple':
      return AutocompleteMultiple
    case 'autocompleteMultiple2':
      return AutocompleteMultiple2
    case 'boolean':
      return Boolean
    case 'date':
      return Date
    case 'datetime':
      return DateTime
    case 'email':
      return Email
    case 'expensesTable':
      return ExpensesTable
    case 'employeesTable':
      return EmployeesTable
    case 'file':
      return File
    case 'number':
      return Number
    case 'password':
      return Password
    case 'select':
      return Select
    case 'text':
      return Text
    case 'time':
      return Time
    default:
      return Text
  }
}
export default getInput
