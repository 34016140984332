import axios from '../_helpers/axios'

const route = 'ContactUs'.toLowerCase()
const createContactUs = async (createBody) =>
  axios.post(`v1/${route}`, createBody)
const getContactUss = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getContactUs = async (id) => axios.get(`v1/${route}/${id}`)
const updateContactUs = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteContactUs = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createContactUs,
  getContactUss,
  getContactUs,
  updateContactUs,
  deleteContactUs,
}
