import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { quotationService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Quotation = function () {
  const collectionName = 'Quotations'
  const singleCollectionName = 'Quotation'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    quotationService.getQuotations
  )
  const createMutation = reactQuery.createMutation(
    quotationService.createQuotation,
    singleCollectionName,
    queryKey
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams(
              objectsHelper.cleanEmpty({
                ...Object.fromEntries([...searchParams]),
                ...args,
                query: collectionName,
              })
            ),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'month',
                label: 'Month',
                type: 'number',
                value: queryParams.month,
                max: 12,
              },
              {
                name: 'year',
                label: 'Year',
                type: 'number',
                value: queryParams.year,
              },
              {
                name: 'status',
                label: 'Status',
                type: 'select',
                options: ['', 'All', ...types.eventStatusTypes].map(
                  (eventStatusType) => ({
                    label: eventStatusType,
                    value: eventStatusType,
                  })
                ),
                value: queryParams.status,
              },
              {
                name: 'contacted',
                label: 'Contacted',
                type: 'select',
                options: ['', ...types.boolean].map((boolean) => ({
                  label: boolean,
                  value: boolean,
                })),
                value: queryParams.contacted,
              },
              {
                name: 'importance',
                label: 'Importance',
                type: 'select',
                options: ['', ...types.colors].map((color) => ({
                  label: color,
                  value: color,
                })),
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            const { start, end } = args
            delete args.start
            delete args.end
            if (start) [args.startHour, args.startMinute] = start.split(':')
            if (end) [args.endHour, args.endMinute] = end.split(':')
            return createMutation.mutate(args)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'eventType',
                label: 'Event Type',
                type: 'select',
                options: types.eventsTypes.map((eventType) => ({
                  label: eventType,
                  value: eventType,
                })),
                required: true,
              },
              ...(body.eventType === 'Wedding'
                ? [
                    {
                      name: 'groomName',
                      label: 'Groom Name',
                      type: 'text',
                      required: true,
                    },
                    {
                      name: 'brideName',
                      label: 'Bride Name',
                      type: 'text',
                    },
                  ]
                : []),
              ...(body.eventType === 'Corporate'
                ? [
                    {
                      name: 'companyName',
                      label: 'Company Name',
                      type: 'text',
                      required: true,
                    },
                  ]
                : []),
              ...(body.eventType === 'Private'
                ? [
                    {
                      name: 'typeOfEvent',
                      label: 'Type Of Event',
                      type: 'text',
                      required: true,
                    },
                  ]
                : []),
              {
                name: 'fullDate',
                label: 'Date',
                type: 'boolean',
              },
              ...(body.fullDate
                ? [
                    {
                      name: 'date',
                      label: 'Date',
                      type: 'date',
                      required: true,
                    },
                    {
                      name: 'endDate',
                      label: 'End Date',
                      type: 'date',
                    },
                  ]
                : [
                    {
                      name: 'month',
                      label: 'Month',
                      type: 'number',
                      max: 12,
                      required: true,
                    },
                    {
                      name: 'year',
                      label: 'Year',
                      type: 'number',
                      required: true,
                    },
                  ]),
              {
                name: 'start',
                label: 'Start Time',
                type: 'time',
              },
              {
                name: 'end',
                label: 'End Time',
                type: 'time',
              },
              {
                name: 'location',
                label: 'Location',
                type: 'text',
              },
              {
                name: 'locationLink',
                label: 'Location Link',
                type: 'text',
              },
              {
                name: 'indoors',
                label: 'Indoors',
                type: 'boolean',
              },
              {
                name: 'outdoors',
                label: 'Outdoors',
                type: 'boolean',
              },
              {
                name: 'numberOfGuests',
                label: 'Number Of Guests',
                type: 'number',
              },
              {
                name: 'numberOfBars',
                label: 'Number Of Bars',
                type: 'number',
              },
              {
                name: 'softDrinks',
                label: 'Soft Drinks',
                type: 'boolean',
              },
              {
                name: 'redBull',
                label: 'Red Bull',
                type: 'boolean',
              },
              {
                name: 'ice',
                label: 'Ice',
                type: 'boolean',
              },
              {
                name: 'barRental',
                label: 'Bar Rental',
                type: 'boolean',
              },
              {
                name: 'glasses',
                label: 'Glasses',
                type: 'boolean',
              },
              {
                name: 'waiters',
                label: 'Waiters',
                type: 'boolean',
              },
              {
                name: 'coatMan',
                label: 'Coat Man',
                type: 'boolean',
              },
              {
                name: 'shotsPackage',
                label: 'Shots Package',
                type: 'boolean',
              },
              {
                name: 'contactName',
                label: 'Contact Name',
                type: 'text',
              },
              {
                name: 'contactEmail',
                label: 'Contact Email',
                type: 'email',
              },
              {
                name: 'contactNumber',
                label: 'Contact Number',
                type: 'text',
              },
              {
                name: 'planner',
                label: 'Planner',
                type: 'text',
              },
              {
                name: 'tastingSessionDate',
                label: 'Tasting Session Date',
                type: 'date',
              },
              {
                name: 'status',
                label: 'Status',
                type: 'select',
                options: types.eventStatusTypes.map((eventStatusType) => ({
                  label: eventStatusType,
                  value: eventStatusType,
                })),
              },
              {
                name: 'reason',
                label: 'Reason',
                type: 'text',
              },
              {
                name: 'importance',
                label: 'Importance',
                type: 'select',
                options: types.colors.map((color) => ({
                  label: color,
                  value: color,
                })),
              },
              {
                name: 'contacted',
                label: 'Contacted',
                type: 'boolean',
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      notToShow={[
        'brideName',
        'groomName',
        'companyName',
        'typeOfEvent',
        'eventType',
        'startHour',
        'startMinute',
        'endHour',
        'endMinute',
        'indoors',
        'outdoors',
        'numberOfGuests',
        'numberOfBars',
        'softDrinks',
        'redBull',
        'coatMan',
        'shotsPackage',
        'glasses',
        'barRental',
        'contactName',
        'contactEmail',
        'contactNumber',
        'reason',
        'budget',
        'planner',
        'tastingSessionDate',
        'note',
        'importance',
        'contacted',
        'locationLink',
        'endDate',
        'day',
        'month',
        'year',
      ]}
    />
  )
}
export default Quotation
