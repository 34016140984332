import React, { useState } from 'react'

import reactQuery from '../../_helpers/reactQuery'
import { typeService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Type = function () {
  const collectionName = 'Types'
  const singleCollectionName = 'Type'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {}
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    typeService.getTypes
  )
  const createMutation = reactQuery.createMutation(
    typeService.createType,
    singleCollectionName,
    queryKey
  )
  return (
    <Document
      tableActions={[
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => createMutation.mutate(args),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'name',
                label: 'Name',
                type: 'text',
                required: true,
              },
              {
                name: 'type',
                label: 'Type',
                type: 'select',
                options: types.types.map((type) => ({
                  label: type,
                  value: type,
                })),
                required: true,
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
    />
  )
}
export default Type
