import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CelebrationIcon from '@mui/icons-material/Celebration'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import DescriptionIcon from '@mui/icons-material/Description'
import EventNoteIcon from '@mui/icons-material/EventNote'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import FontDownloadIcon from '@mui/icons-material/FontDownload'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MenuIcon from '@mui/icons-material/Menu'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PeopleIcon from '@mui/icons-material/People'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import RedeemIcon from '@mui/icons-material/Redeem'
import StoreIcon from '@mui/icons-material/Store'
import WorkIcon from '@mui/icons-material/Work'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import useTheme from '@mui/material/styles/useTheme'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { authService } from '../../_services'
// import Logo from '../../assets/images/logo.png'
import translate from '../../locales/en'
import RoleBasedGuard from '../Guards/RoleBasedGuard'
import Routes from '../Routes/Routes'

const drawerWidth = 190
const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', backgroundColor: '#18191b' },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // backgroundColor: '#242527',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  // menuButton: { marginRight: 36, color: 'white' },
  hide: { display: 'none' },
  drawer: {
    width: drawerWidth,
    // flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#242527',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    backgroundColor: '#242527',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: 'hidden',
    minHeight: '100vh',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.6em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#18191b',
      outline: '2px solid slategrey',
    },
  },
}))
const Layout = function () {
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(!isMobile)
  const openDrawer = () => setOpen(true)
  const closeDrawer = () => setOpen(false)
  /**
   * Find icons here:
   * https://material-ui.com/components/material-icons/
   */
  const drawerItems = [
    {
      name: 'Requests',
      route: '/quotations',
      icon: <ListAltIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Events',
      route: '/events',
      icon: <CelebrationIcon />,
      roles: ['owner', 'admin', 'editor'],
    },
    {
      name: 'Employees',
      route: '/employees',
      icon: <WorkIcon />,
      roles: ['owner', 'admin', 'editor'],
    },
    {
      name: 'Expense',
      route: '/expenses',
      icon: <MonetizationOnIcon />,
      roles: ['owner', 'admin', 'editor'],
    },
    {
      name: 'Income',
      route: '/incomes',
      icon: <AttachMoneyIcon />,
      roles: ['owner'],
    },
    {
      name: 'Suppliers',
      route: '/suppliers',
      icon: <StoreIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Terms',
      route: '/types',
      icon: <FontDownloadIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Auto Fills',
      route: '/notes',
      icon: <DescriptionIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Messages',
      route: '/contactUs',
      icon: <ContactSupportIcon />,
      roles: ['owner', 'admin'],
    },
    {
      name: 'Users',
      route: '/users',
      icon: <PeopleIcon />,
      roles: ['owner'],
    },
    {
      name: 'Cashflow',
      route: '/cashflow',
      icon: <PointOfSaleIcon />,
      roles: ['owner'],
    },
    {
      name: 'Paycheck',
      route: '/paycheck',
      icon: <RedeemIcon />,
      roles: ['owner'],
    },
  ].map((item, i) => (
    <RoleBasedGuard key={i} permitedRoles={item.roles}>
      <ListItem
        button
        key={item.name}
        className={
          `/${location.pathname.split('/')[1].toLowerCase()}` ===
          item.route.toLowerCase()
            ? 'activeMenu'
            : ''
        }
        onClick={() => navigate(item.route)}
      >
        <Tooltip title={translate[item.name]} placement="right">
          <ListItemIcon style={{ color: 'white' }}>{item.icon}</ListItemIcon>
        </Tooltip>
        <ListItemText
          primary={translate[item.name]}
          style={{ color: 'white' }}
        />
      </ListItem>
      <Divider key={i} style={{ backgroundColor: 'rgba(255,255,255,0.12)' }} />
    </RoleBasedGuard>
  ))
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={openDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className="spread menu">
            {/* <img src={Logo} width="40px" alt="" /> */}
            <Button onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
            </Button>
            {!isMobile ? (
              <Typography variant="h6">
                {translate['Drinks On Us Admin Panel']}
              </Typography>
            ) : null}
            <div className="actions">
              <Link to="/calendar">
                <EventNoteIcon />
              </Link>
              <Link to="/profile">
                <AccountCircleIcon />
              </Link>
              <Link onClick={() => authService.logout()} to="/">
                <ExitToAppIcon />
              </Link>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={closeDrawer} style={{ color: 'white' }}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>{drawerItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Routes />
      </main>
    </div>
  )
}
export default Layout
