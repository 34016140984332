import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import {
  expenseService,
  itemService,
  supplierService,
  typeService,
} from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'

const Expense = function ({ eventId, eventName, employeeId, employeeName }) {
  const collectionName = 'Expenses'
  const singleCollectionName = 'Expense'
  const queryKey = collectionName.toLowerCase()
  const [body, setBody] = useState({})
  const defaultQueryParams = {
    ...(eventId && { eventId }),
    ...(employeeId && { employeeId }),
  }
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const getQuery = reactQuery.getQuery(
    queryKey,
    queryParams,
    expenseService.getExpenses
  )
  const createMutation = reactQuery.createMutation(
    expenseService.createExpense,
    singleCollectionName,
    queryKey,
    [['event', { id: eventId }]]
  )
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Document
      tableActions={[
        {
          buttonLabel: translate.Filters,
          buttonColor: 'primary',
          onSubmit: (args) =>
            setSearchParams(
              objectsHelper.cleanEmpty({
                ...Object.fromEntries([...searchParams]),
                ...args,
                query: collectionName,
              })
            ),
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          withForm: true,
          form: {
            title: `${translate.Filter} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'month',
                label: 'Month',
                type: 'number',
                value: queryParams.month,
                max: 12,
              },
              {
                name: 'year',
                label: 'Year',
                type: 'number',
                value: queryParams.year,
              },
              {
                name: 'typeName',
                label: 'Type',
                type: 'autocomplete',
                queryKey: 'types',
                getApi: typeService.getTypes,
                keys: { _id: 'typeId', name: 'typeName' },
                value: {
                  typeId: queryParams.typeId,
                  typeName: queryParams.typeName,
                },
                options: { type: 'expenses' },
              },
              {
                name: 'itemName',
                label: 'Item',
                type: 'autocomplete',
                queryKey: 'items',
                getApi: itemService.getItems,
                keys: { _id: 'itemId', name: 'itemName' },
                value: {
                  itemId: queryParams.itemId,
                  itemName: queryParams.itemName,
                },
                options: { typeId: body.typeId },
              },
              {
                name: 'supplierName',
                label: 'Supplier',
                type: 'autocomplete',
                queryKey: 'suppliers',
                getApi: supplierService.getSuppliers,
                keys: { _id: 'supplierId', name: 'supplierName' },
                value: {
                  supplierId: queryParams.supplierId,
                  supplierName: queryParams.supplierName,
                },
              },
            ],
          },
          withReset: true,
          resetFunction: () => setSearchParams(),
        },
        {
          buttonLabel: `${translate.Add} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            if (eventId) {
              args.eventId = eventId
              args.eventName = eventName
              args.type = 'events'
              args.typeId = '66328d0fc2984641f66417dc'
              args.typeName = 'Wedding Expenses'
            }
            if (employeeId) {
              args.employeeId = employeeId
              args.employeeName = employeeName
              args.type = 'employees'
            }
            createMutation.mutate(args)
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              ...(!eventId
                ? [
                    {
                      name: 'typeName',
                      label: 'Type',
                      type: 'autocomplete',
                      required: true,
                      queryKey: 'types',
                      getApi: typeService.getTypes,
                      keys: { _id: 'typeId', name: 'typeName' },
                      value: {},
                      options: { type: 'expenses' },
                    },
                  ]
                : []),
              {
                name: 'itemName',
                label: 'Item',
                type: 'autocomplete',
                required: true,
                queryKey: 'items',
                getApi: itemService.getItems,
                keys: { _id: 'itemId', name: 'itemName' },
                value: eventId
                  ? {
                      itemId: '66328d44c2984641f66417df',
                      itemName: 'Other',
                    }
                  : {},
                options: {
                  typeId: eventId ? '66328d0fc2984641f66417dc' : body.typeId,
                },
              },
              ...(!eventId
                ? [
                    {
                      name: 'supplierName',
                      label: 'Supplier',
                      type: 'autocomplete',
                      queryKey: 'suppliers',
                      getApi: supplierService.getSuppliers,
                      keys: { _id: 'supplierId', name: 'supplierName' },
                    },
                  ]
                : []),
              {
                name: 'value',
                label: 'Value',
                type: 'number',
                required: true,
              },
              {
                name: 'reason',
                label: 'Reason',
                type: 'text',
              },
              {
                name: 'check',
                label: 'Check',
                type: 'boolean',
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
              },
            ],
          },
        },
        {
          buttonLabel: `${translate.Add} ${translate.Multiple} ${translate[singleCollectionName]}`,
          buttonColor: 'primary',
          onSubmit: (args) => {
            if (eventId) {
              args.eventId = eventId
              args.eventName = eventName
              args.type = 'events'
              args.typeId = '66328d0fc2984641f66417dc'
              args.typeName = 'Wedding Expenses'
            }
            if (employeeId) {
              args.employeeId = employeeId
              args.employeeName = employeeName
              args.type = 'employees'
            }
            args.expenses.forEach((expense) => {
              const { expenses, ...rest } = args
              createMutation.mutate({
                ...rest,
                reason: expense.reason,
                value: expense.value,
              })
            })
          },
          body,
          setBody,
          withClear: true,
          clearFunction: () => setBody({}),
          form: {
            title: `${translate.Add} ${translate[singleCollectionName]}`,
            inputs: [
              {
                name: 'typeName',
                label: 'Type',
                type: 'autocomplete',
                required: true,
                queryKey: 'types',
                getApi: typeService.getTypes,
                keys: { _id: 'typeId', name: 'typeName' },
                value: {
                  typeId: '660eb5b194df2d9ca22ca42f',
                  typeName: 'Events Expenses',
                },
              },
              {
                name: 'itemName',
                label: 'Item',
                type: 'autocomplete',
                required: true,
                queryKey: 'items',
                getApi: itemService.getItems,
                keys: { _id: 'itemId', name: 'itemName' },
                value: {
                  itemId: '660ecd5a94df2d9ca22ca434',
                  itemName: 'General ',
                },
                options: { typeId: body.typeId },
              },
              {
                name: 'supplierName',
                label: 'Supplier',
                type: 'autocomplete',
                queryKey: 'suppliers',
                getApi: supplierService.getSuppliers,
                keys: { _id: 'supplierId', name: 'supplierName' },
              },
              {
                name: 'expenses',
                label: 'Expenses',
                type: 'expensesTable',
              },
              {
                name: 'check',
                label: 'Check',
                type: 'boolean',
              },
              {
                name: 'note',
                label: 'Note',
                type: 'text',
              },
            ],
          },
        },
      ]}
      title={collectionName}
      getQuery={getQuery}
      queryKey={queryKey}
      defaultQueryParams={defaultQueryParams}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      many
      navigate={eventId || employeeId ? [queryKey, '_id'] : null}
      notToShow={['type', 'check']}
    />
  )
}
export default Expense
