module.exports = {
  'Accessories And Frame': 'Accessories And Frame',
  Add: 'Add',
  'Add Ons': 'Add Ons',
  'Are you sure you want to create this':
    'Are you sure you want to create this',
  'Are you sure you want to delete this':
    'Are you sure you want to delete this',
  'Auto Fills': 'Auto Fills',
  'Average Rating': 'Average Rating',
  'Bar Rental': 'Bar Rental',
  Bottles: 'Bottles',
  'Bride Name': 'Bride Name',
  Budget: 'Budget',
  'Cake Shots': 'Cake Shots',
  Cancel: 'Cancel',
  Check: 'Check',
  Client: 'Client',
  'Client Name': 'Client Name',
  Clients: 'Clients',
  'Coat Man': 'Coat Man',
  Cocktails: 'Cocktails',
  'Collection Name': 'Collection Name',
  Color: 'Color',
  Comment: 'Comment',
  'Company Name': 'Company Name',
  Compound: 'Compound',
  'Compound Name': 'Compound Name',
  'Compound Price': 'Compound Price',
  Compounds: 'Compounds',
  Confirm: 'Confirm',
  'Contact Email': 'Contact Email',
  'Contact Name': 'Contact Name',
  'Contact Number': 'Contact Number',
  'Contact Us': 'Contact Us',
  ContactUs: 'ContactUs',
  Contacted: 'Contacted',
  Corporate: 'Corporate',
  'Created At': 'Created At',
  'Creation Date': 'Creation Date',
  Date: 'Date',
  Day: 'Day',
  Delete: 'Delete',
  Delivered: 'Delivered',
  'Delivered Units': 'Delivered Units',
  'Delivered Weight': 'Delivered Weight',
  Deliveries: 'Deliveries',
  Delivery: 'Delivery',
  Deliverys: 'Deliverys',
  Done: 'Done',
  'Done Units': 'Done Units',
  'Done Weight': 'Done Weight',
  'Drinks On Us Admin Panel': 'Drinks On Us Admin Panel',
  Email: 'Email',
  Employee: 'Employee',
  'Employee Name': 'Employee Name',
  Employees: 'Employees',
  'End Date': 'End Date',
  'End Hour': 'End Hour',
  'End Minute': 'End Minute',
  'End Time': 'End Time',
  Error: 'Error',
  Event: 'Event',
  'Event Name': 'Event Name',
  'Event Type': 'Event Type',
  Events: 'Events',
  Expense: 'Expense',
  Expenses: 'Expenses',
  Experience: 'Experience',
  'Export CSV': 'Export CSV',
  'Export PDF': 'Export PDF',
  Extras: 'Extras',
  Filter: 'Filter',
  Filters: 'Filters',
  'Follow Up': 'Follow Up',
  Generate: 'Generate',
  Glass: 'Glass',
  Glasses: 'Glasses',
  'Groom Name': 'Groom Name',
  Guests: 'Guests',
  Ice: 'Ice',
  Importance: 'Importance',
  'In Flow Logs': 'In Flow Logs',
  Income: 'Income',
  Incomes: 'Incomes',
  Indoors: 'Indoors',
  Information: 'Information',
  Item: 'Item',
  'Item Name': 'Item Name',
  'Item Type': 'Item Type',
  Items: 'Items',
  'Latest Price': 'Latest Price',
  'Latest Purchase Date': 'Latest Purchase Date',
  'Loading...': 'Loading...',
  Location: 'Location',
  'Location Link': 'Location Link',
  'Logged in': 'Logged in',
  Login: 'Login',
  Media: 'Media',
  Menu: 'Menu',
  Menus: 'Menus',
  Message: 'Message',
  Messages: 'Messages',
  'Missing Information': 'Missing Information',
  Money: 'Money',
  Month: 'Month',
  'Mr X Account': 'Mr X Account',
  'Mr X Name': 'Mr X Name',
  Name: 'Name',
  No: 'No',
  Note: 'Note',
  Notes: 'Notes',
  'Number Of Bars': 'Number Of Bars',
  'Number Of Events': 'Number Of Events',
  'Number Of Guests': 'Number Of Guests',
  'Number of compounds': 'Number of compounds',
  'Number of items': 'Number of items',
  'Number of supplies': 'Number of supplies',
  'On Call': 'On Call',
  Other: 'Other',
  'Out Flow Logs': 'Out Flow Logs',
  Outdoors: 'Outdoors',
  Password: 'Password',
  Percentage: 'Percentage',
  'Phone Number': 'Phone Number',
  Planner: 'Planner',
  'Pre Wedding': 'Pre Wedding',
  Price: 'Price',
  Private: 'Private',
  Purchase: 'Purchase',
  Purchases: 'Purchases',
  Quantity: 'Quantity',
  Quotation: 'Quotation',
  Quotations: 'Quotations',
  Rating: 'Rating',
  Ratings: 'Ratings',
  Reason: 'Reason',
  Receipt: 'Receipt',
  Receipts: 'Receipts',
  Received: 'Received',
  'Red Bull': 'Red Bull',
  Remaining: 'Remaining',
  'Remaining Units': 'Remaining Units',
  'Remaining Weight': 'Remaining Weight',
  Report: 'Report',
  Requests: 'Requests',
  Resala: 'Resala',
  Reset: 'Reset',
  Role: 'Role',
  Schedule: 'Schedule',
  Server: 'Server',
  'Shots Package': 'Shots Package',
  'Soft Drinks': 'Soft Drinks',
  Staff: 'Staff',
  Stage: 'Stage',
  'Start Hour': 'Start Hour',
  'Start Minute': 'Start Minute',
  'Start Time': 'Start Time',
  Status: 'Status',
  Storage: 'Storage',
  'Storage Name': 'Storage Name',
  Storaged: 'Storaged',
  Storages: 'Storages',
  Submit: 'Submit',
  Summary: 'Summary',
  Supplier: 'Supplier',
  'Supplier Name': 'Supplier Name',
  Suppliers: 'Suppliers',
  Supplies: 'Supplies',
  Tasted: 'Tasted',
  'Tasting Session': 'Tasting Session',
  'Tasting Session Date': 'Tasting Session Date',
  Terms: 'Terms',
  'Total Delivered Units': 'Total Delivered Units',
  'Total Delivered Weight': 'Total Delivered Weight',
  'Total Done Units': 'Total Done Units',
  'Total Done Weight': 'Total Done Weight',
  'Total Expenses': 'Total Expenses',
  'Total In Money': 'Total In Money',
  'Total Income': 'Total Income',
  'Total Income Percentage': 'Total Income Percentage',
  'Total Money': 'Total Money',
  'Total Out Money': 'Total Out Money',
  'Total Price': 'Total Price',
  'Total Quantity': 'Total Quantity',
  'Total Received Units': 'Total Received Units',
  'Total Received Weight': 'Total Received Weight',
  'Total Remaining Units': 'Total Remaining Units',
  'Total Remaining Weight': 'Total Remaining Weight',
  Type: 'Type',
  'Type Of Event': 'Type Of Event',
  Types: 'Types',
  Uniform: 'Uniform',
  Units: 'Units',
  Update: 'Update',
  User: 'User',
  Users: 'Users',
  Value: 'Value',
  Waiters: 'Waiters',
  Wedding: 'Wedding',
  Weight: 'Weight',
  'Weight Per Unit': 'Weight Per Unit',
  Working: 'Working',
  Workings: 'Workings',
  Year: 'Year',
  'Year Of Birth': 'Year Of Birth',
  Yes: 'Yes',
  added: 'added',
  'can not be empty': 'can not be empty',
  'could not be': 'could not be',
  deleted: 'deleted',
  edited: 'edited',
  generated: 'generated',
  'is invalid': 'is invalid',
  'is required': 'is required',
  'password must be of minimum length 8 and contain at least 1 letter and 1 number':
    'password must be of minimum length 8 and contain at least 1 letter and 1 number',
  rent: 'rent',
  salaries: 'salaries',
  successfully: 'successfully',
  Multiple: 'Multiple',
  Return: 'Return',
  Underpayment: 'Underpayment',
  Overpayment: 'Overpayment',
  Cashflow: 'Cashflow',
  'Cashflow Type': 'Cashflow Type',
  Paycheck: 'Paycheck',
  Paychecks: 'Paychecks',
}
