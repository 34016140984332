/**
 * Display content only if user has permitted role
 */
import { jwtDecode } from "jwt-decode";
import React from 'react'

import localStorageUtils from '../../_helpers/localStorageUtils'
import Guard from './Guard'

const RoleBasedGuard = function (props) {
  const ACCESS_TOKEN = localStorageUtils.get(
    localStorageUtils.availableKeys.ACCESS_TOKEN
  )
  const decodedToken = jwtDecode(ACCESS_TOKEN)
  return (
    <Guard condition={props.permitedRoles.includes(decodedToken.role)}>
      {props.children}
    </Guard>
  )
}
export default RoleBasedGuard
