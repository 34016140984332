import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { employeeService } from '../../_services'
import translate from '../../locales/en'
import Document from '../Abstract/Document'
import Expenses from '../Collections/Expenses'

const Employee = function () {
  const collectionName = 'Employees'
  const singleCollectionName = 'Employee'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    employeeService.getEmployee
  )
  const updateMutation = reactQuery.updateMutation(
    employeeService.updateEmployee,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const deleteMutation = reactQuery.deleteMutation(
    employeeService.deleteEmployee,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <>
      <Document
        actions={[
          {
            buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              const newBody = objectsHelper.compare(getQuery.data, args)
              const { media } = newBody
              delete newBody.media
              const formData = objectsHelper.getFormData(newBody)
              if (media && media.length)
                Object.keys(media).forEach((key) =>
                  formData.append('media', media[key])
                )
              if (Object.keys(newBody).length || (media && media.length))
                return updateMutation.mutate(formData)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Update} ${translate[singleCollectionName]}`,
              inputs: [
                {
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  value: getQuery.data.name,
                },
                {
                  name: 'role',
                  label: 'Role',
                  type: 'text',
                  value: getQuery.data.role,
                },
                {
                  name: 'yearOfBirth',
                  label: 'Year Of Birth',
                  type: 'number',
                  value: getQuery.data.yearOfBirth,
                },
                {
                  name: 'experience',
                  label: 'Experience',
                  type: 'number',
                  value: getQuery.data.experience,
                },
                {
                  name: 'phoneNumber',
                  label: 'Phone Number',
                  type: 'text',
                  value: getQuery.data.phoneNumber,
                },
                {
                  name: 'note',
                  label: 'Note',
                  type: 'text',
                  value: getQuery.data.note,
                },
                {
                  name: 'media',
                  label: 'Media',
                  type: 'file',
                  accept: 'image/*',
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
            buttonColor: 'secondary',
            onSubmit: () => deleteMutation.mutate(),
            form: {
              title: `${translate.Delete} ${translate[singleCollectionName]}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
          },
        ]}
        title={singleCollectionName}
        getQuery={getQuery}
        many={false}
      />
      <Expenses
        employeeId={getQuery.data._id}
        employeeName={getQuery.data.name}
      />
    </>
  )
}
export default Employee
