import axios from '../_helpers/axios'

const route = 'Notes'.toLowerCase()
const createNote = async (createBody) => axios.post(`v1/${route}`, createBody)
const getNotes = async (options) =>
  axios.get(
    `v1/${route}?${Object.keys(options)
      .map((key) => `${options[key] ? `&${key}=${options[key]}` : ''}`)
      .join('')}`
  )
const getNote = async (id) => axios.get(`v1/${route}/${id}`)
const updateNote = async (id, updateBody) =>
  axios.patch(`v1/${route}/${id}`, updateBody)
const deleteNote = async (id) => axios.delete(`v1/${route}/${id}`)
export default {
  createNote,
  getNotes,
  getNote,
  updateNote,
  deleteNote,
}
