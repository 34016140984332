import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import objectsHelper from '../../_helpers/objectUtils'
import reactQuery from '../../_helpers/reactQuery'
import { typeService } from '../../_services'
import { types } from '../../constants'
import translate from '../../locales/en'
import Document from '../Abstract/Document'
import Items from '../Collections/Items'

const Type = function () {
  const collectionName = 'Types'
  const singleCollectionName = 'Type'
  const queryKey = collectionName.toLowerCase()
  const singleQueryKey = singleCollectionName.toLowerCase()
  const params = useParams()
  const getQuery = reactQuery.getOneQuery(
    singleQueryKey,
    params.id,
    typeService.getType
  )
  const updateMutation = reactQuery.updateMutation(
    typeService.updateType,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey,
    [['items', { typeId: params.id }]]
  )
  const deleteMutation = reactQuery.deleteMutation(
    typeService.deleteType,
    params.id,
    singleCollectionName,
    singleQueryKey,
    queryKey
  )
  const [body, setBody] = useState({})
  if (getQuery.isLoading)
    return <span className="spanText">{translate['Loading...']}</span>
  return (
    <>
      <Document
        actions={[
          {
            buttonLabel: `${translate.Update} ${translate[singleCollectionName]}`,
            buttonColor: 'primary',
            onSubmit: (args) => {
              const newBody = objectsHelper.compare(getQuery.data, args)
              if (Object.keys(newBody).length)
                return updateMutation.mutate(newBody)
            },
            body,
            setBody,
            withClear: true,
            clearFunction: () => setBody({}),
            form: {
              title: `${translate.Update} ${translate[singleCollectionName]}`,
              inputs: [
                {
                  name: 'name',
                  label: 'Name',
                  type: 'text',
                  value: getQuery.data.name,
                },
                {
                  name: 'type',
                  label: 'Type',
                  type: 'select',
                  options: types.types.map((type) => ({
                    label: type,
                    value: type,
                  })),
                  value: getQuery.data.type,
                },
              ],
            },
          },
          {
            buttonLabel: `${translate.Delete} ${translate[singleCollectionName]}`,
            buttonColor: 'secondary',
            onSubmit: () => deleteMutation.mutate(),
            form: {
              title: `${translate.Delete} ${translate[singleCollectionName]}`,
            },
            confirm: true,
            info: `${translate['Are you sure you want to delete this']} ${translate[singleCollectionName]}`,
          },
        ]}
        title={singleCollectionName}
        getQuery={getQuery}
        many={false}
      />
      <Items typeName={getQuery.data.name} typeId={getQuery.data._id} />
    </>
  )
}
export default Type
